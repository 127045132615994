import { lazy } from 'react';

// Layout
export const TableData = lazy(() => import('./TableData'));
// export const ImageComp = lazy(() => import('./ImageComp'));
// export const IconComp = lazy(() => import('./IconComp'));
// export const SvgComp = lazy(() => import('./SvgComp'));
export const CategorySquare = lazy(() => import('./CategorySquare'));
export const HorizontalScrollable = lazy(() =>
  import('./HorizontalScrollable')
);
// export const ClickHref = lazy(() => import('./ClickHref'));
// export const ExternalHref = lazy(() => import('./ExternalHref'));
// export const JmeDivider = lazy(() => import('./JmeDivider'));
// export const JmeBox = lazy(() => import('./JmeBox'));
export const Faqs = lazy(() => import('./Faqs'));
export const ProsCons = lazy(() => import('./ProsCons'));
// export const JmeContainer = lazy(() => import('./JmeContainer'));

// Layout -> Grid
// export const JmeGrid = lazy(() => import('./grid/JmeGrid'));
export const JmeGridWithSidePreview = lazy(() =>
  import('./grid/JmeGridWithSidePreview')
);
export const JmePreviewProvider = lazy(() =>
  import('./grid/JmePreviewProvider')
);
// export const DataGrid = lazy(() => import('./grid/DataGrid'));
export const JmeGridDynamic = lazy(() => import('./grid/JmeGridDynamic'));
export const JmeGridDynamicColumn = lazy(() =>
  import('./grid/JmeGridDynamicColumn')
);
// export const GridRow = lazy(() => import('./grid/GridRow'));
// export const GridColumn = lazy(() => import('./grid/GridColumn'));
// export const JmeColumnsInARow = lazy(() => import('./grid/JmeColumnsInARow'));

// Layout -> Carousel
// export const CarouselComp = lazy(() => import('./Carousel/CarouselComp'));

// Layout -> Card
// export const JmeCard = lazy(() => import('./Card/JmeCard'));
export const IndustrySearch = lazy(() => import('./IndustrySearch'));
export const GeneralTabComponent = lazy(() => import('./GeneralTabComponent'));
export const GeneralTabBox = lazy(() => import('./GeneralTabBox'));
export const JmeCardContent = lazy(() => import('./Card/JmeCardContent'));
export const JmeCardActionArea = lazy(() => import('./Card/JmeCardActionArea'));
export const JmeCardActions = lazy(() => import('./Card/JmeCardActions'));
export const JmeCardMedia = lazy(() => import('./Card/JmeCardMedia'));
// Layout -> Guidlines
export const GuidelinesLink = lazy(() => import('./Guidelines/GuidelinesLink'));
export const GuidelinesTitle = lazy(() =>
  import('./Guidelines/GuidelinesTitle')
);
export const GridContainer = lazy(() => import('./grid/GridContainer'));
export const GridItem = lazy(() => import('./grid/GridItem'));

// export const InfiniteScroll = lazy(() => import('./InfiniteScroll'));
// export const InfiniteScrollUrlSearchParams = lazy(() => import('./InfiniteScrollUrlSearchParams'));

// Layout
export { default as CarouselComp } from './Carousel/CarouselComp';
// export { default as TableData } from './TableData';
export { default as ImageComp } from './ImageComp';
export { default as SimpleVideo } from './SimpleVideo';
export { default as SimpleAudio } from './SimpleAudio';
export { default as IconComp } from './IconComp';
export { default as SvgComp } from './SvgComp';
// export { default as CategorySquare } from './CategorySquare';
// export { default as HorizontalScrollable } from './HorizontalScrollable';
export { default as ClickHref } from './ClickHref';
export { default as ExternalHref } from './ExternalHref';
export { default as JmeDivider } from './JmeDivider';
export { default as JmeBox } from './JmeBox';
// export { default as Faqs } from './Faqs';
// export { default as ProsCons } from './ProsCons';
export { default as JmeContainer } from './JmeContainer';

// Layout -> Grid
export { default as JmeGrid } from './grid/JmeGrid';

// export { default as JmeGridWithSidePreview } from './grid/JmeGridWithSidePreview';
// export { default as JmePreviewProvider } from './grid/JmePreviewProvider';
export { default as DataGrid } from './grid/DataGrid';
// export { default as JmeGridDynamic } from './grid/JmeGridDynamic';
// export { default as JmeGridDynamicColumn } from './grid/JmeGridDynamicColumn';
export { default as GridRow } from './grid/GridRow';
export { default as GridColumn } from './grid/GridColumn';
export { default as JmeColumnsInARow } from './grid/JmeColumnsInARow';

// Layout -> Card
export { default as JmeCard } from './Card/JmeCard';
// export { default as JmeCardContent } from './Card/JmeCardContent';
// export { default as JmeCardActionArea } from './Card/JmeCardActionArea';
// export { default as JmeCardActions } from './Card/JmeCardActions';
// export { default as JmeCardMedia } from './Card/JmeCardMedia';

// Layout -> Guidlines
// export { default as GuidelinesLink } from './Guidelines/GuidelinesLink';
// export { default as GuidelinesTitle } from './Guidelines/GuidelinesTitle';

export { default as InfiniteScroll } from './InfiniteScroll';
export { default as InfiniteScrollBox } from './InfiniteScrollBox';
export { default as InfiniteScrollPage } from './InfiniteScrollPage';
export { default as InfiniteScrollUrlSearchParams } from './InfiniteScrollUrlSearchParams';

export { default as JmeClampBox } from './JmeClampBox';
